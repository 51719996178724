<template>
  <div class="button">
    <h2 class="button__title">Buttons</h2>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button Default</h3>
      <MainButtonNew
        :type="mainButton.type"
        :title="mainButton.title"
        :icon-name="mainButton.iconName"
        :size="mainButton.size"
        :device-type="mainButton.deviceType"
        :position-icon="mainButton.positionIcon"
        :has-background="mainButton.hasBackground"
      />
    </div>

    <div class="button__wrap">
      <h3 class="button__subtitle">Input Props</h3>
      <ul class="button__list">
        <li class="button__item">
          <span>:type </span>
          <select v-model="mainButton.type">
            <option value="primary">primary</option>
            <option value="secondary">secondary</option>
            <option value="triatry">triatry</option>
            <option value="outline">outline</option>
            <option value="disabled">disabled</option>
          </select>
          - тип кнопки
        </li>

        <li class="button__item">
          <span>:title </span>
          <input type="text" v-model="mainButton.title" />
          - заголовок внутри кнопки
        </li>
        <li class="button__item">
          <span>:icon-name </span> <input type="text" v-model="mainButton.iconName" /> - иконка
        </li>
        <li class="button__item">
          <span>:position-icon </span>
          <select v-model="mainButton.positionIcon">
            <option value="none">none</option>
            <option value="left">left</option>
            <option value="right">right</option>
            <option value="left-and-right">left-and-right</option>
            <option value="center">center</option>
          </select>
          - позиция иконки
        </li>
        <li class="button__item">
          <span>:size </span>
          <select v-model="mainButton.size">
            <option value="small">small</option>
            <option value="medium">medium</option>
            <option value="large">large</option>
          </select>
          - размер кнопки
        </li>
        <li class="button__item">
          <span>:device-type </span>
          <select v-model="mainButton.deviceType">
            <option value="pc">pc</option>
            <option value="tablet">tablet</option>
            <option value="mobile">mobile</option>
          </select>
          - стиль под конкретный тип устройства
        </li>

        <li class="button__item">
          <span>:has-background </span>
          <input type="checkbox" v-model="mainButton.hasBackground" /> - наличие фона
        </li>
      </ul>
    </div>

    <div class="button__wrap">
      <h3 class="button__subtitle">Button Default</h3>
      <MainButton />
    </div>
    <div class="button__wrap">
      <h3 class="input__subtitle">Input Props</h3>
      <ul class="input__list">
        <li class="input__item"><span>title</span> - Заголовок внутри кнопки</li>
        <li class="input__item"><span>count</span> - count внутри кнопки</li>
        <li class="input__item"><span>icon</span> - иконка слева от текста</li>
        <li class="input__item"><span>type</span> - тип кнопки определяющий стиль</li>
        <li class="input__item"><span>loader</span> - true/false лоадер внутри кнопки</li>
        <li class="input__item"><span>eventType</span> - тип кнопки например submit/default</li>
        <li class="input__item"><span>@handleButton</span> - обработка события нажатия кнопки</li>
      </ul>
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button with title</h3>
      <MainButton title="Заголовок" />
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button with count</h3>
      <MainButton :count="10" />
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button with icon</h3>
      <MainButton icon="backup" />
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button with loader</h3>
      <MainButton :loader="true" />
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button with eventType</h3>
      <MainButton eventType="submit" title="Submit" />
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button type cancel</h3>
      <MainButton type="cancel" />
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button type disabled</h3>
      <MainButton type="disabled" />
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button type create-light</h3>
      <MainButton type="create-light" />
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button type round</h3>
      <MainButton type="round" :title="''" icon="backup" />
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button type outline</h3>
      <MainButton type="outline" />
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button type mobile</h3>
      <MainButton type="mobile" />
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button type filter-list</h3>
      <MainButton type="filter-list" />
    </div>
    <h2 class="button__title">AOP Buttons</h2>
    <div class="button__wrap">
      <h3 class="button__subtitle">AOP Button Default</h3>
      <Button />
    </div>
    <div class="button__wrap">
      <h3 class="input__subtitle">Input Props</h3>
      <ul class="input__list">
        <li class="input__item"><span>title</span> - Заголовок внутри кнопки</li>
        <li class="input__item"><span>count</span> - count внутри кнопки</li>
        <li class="input__item"><span>icon</span> - иконка слева от текста</li>
        <li class="input__item"><span>type</span> - тип кнопки определяющий стиль</li>
        <li class="input__item"><span>iconRight</span> - иконка располагается справа</li>
        <li class="input__item"><span>loader</span> - true/false лоадер внутри кнопки</li>
        <li class="input__item"><span>eventType</span> - тип кнопки например submit/default</li>
        <li class="input__item"><span>@handleButton</span> - обработка события нажатия кнопки</li>
      </ul>
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button Large</h3>
      <Button :large="true" />
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button Small</h3>
      <Button :small="true" />
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button Main</h3>
      <Button type="main" />
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button Main with Icon Right</h3>
      <Button type="main" :iconRight="true" icon="plus" />
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button Disabled</h3>
      <Button type="disabled" />
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button Main Disabled</h3>
      <Button type="main-disabled" :iconRight="true" icon="down" />
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button Cancel</h3>
      <Button type="cancel" />
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button Main Cancel</h3>
      <Button type="main-cancel" />
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button White</h3>
      <Button type="white" />
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button With Icon</h3>
      <Button icon="plus" />
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button Simple</h3>
      <Button type="simple" />
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button Simple With Icon</h3>
      <Button type="simple" icon="plus" />
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button Loader</h3>
      <Button :loader="true" />
    </div>
    <div class="button__wrap">
      <h3 class="button__subtitle">Button White Loader</h3>
      <Button type="white" :loader="true" />
    </div>
  </div>
</template>

<script>
import MainButton from "@/modules/UiKit/components/buttons/MainButton";
import MainButtonNew from "@/aopV2/components/UIComponents/Button/MainButtonNew";

import Button from "@/modules/UiKit/components/buttons/Button";

export default {
  name: "ButtonPage",
  mixins: [],
  props: {},
  components: { Button, MainButton, MainButtonNew },
  data() {
    return {
      mainButton: {
        type: "primary",
        title: "Кнопка",
        iconName: "",
        size: "medium",
        deviceType: "pc",
        positionIcon: "none",
        hasBackground: true
      }
    };
  },
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/colors";

.button {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__wrap {
    width: 48%;
    margin: 24px 0;

    &_default {
      width: 100%;
    }
  }

  &__title {
    width: 100%;
    margin: 0 0 24px;
    color: $text-default;
  }

  &__subtitle {
    margin: 0 0 16px;
    padding: 0 0 16px;
    border-bottom: 1px solid $border-disabled;
    color: $text-default;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    margin: 0 0 8px;
    color: $text-default;
    font-size: 14px;
    line-height: 20px;

    span {
      font-weight: bold;
    }
  }
}
</style>
